import { CustomMessageType } from "@custom-types/CustomMessageType";
import { Message } from "@custom-types/Message";
import { getRedeemableIcon } from "@screens/experiences/components/helpers/redeemable.helper";
import { ASSET, REDEEMABLE } from "./ExpercienceModel";
import NFT from "./NFTModel";

export class CustomMessageModel {
    _id?: string;
    type?: CustomMessageType;
    client?: any;
    address?: string;
    amount?: string;
    currencyName?: string;
    currencyId?: string;
    network?: string;
    transactionId?: string;
    hash?: string;
    nft?: Partial<NFT>;
    redeemable?: Partial<REDEEMABLE>;
    asset?: Partial<ASSET>;

    public static create(data): CustomMessageModel {
        return Object.assign(new CustomMessageModel(), data);
    }

    generateId() {
        const now = new Date();
        return `${now}`;
    }

    transformToRegularMessage(chat) {
        const msg: any = {
            _id: this.generateId(),
            text: `${this.type == CustomMessageType.CryptoAddress ? `${this.currencyName} Address` : ""}${
                this.type == CustomMessageType.PayOrder ? `${this.currencyName} Pay Order` : ""
            }${this.type == CustomMessageType.SendCrypto ? `${this.currencyName} Transaction` : ""}${
                this.type == CustomMessageType.NFT ? `NFT (${this.nft?.name})` : ""
            }${this.type == CustomMessageType.SendNFT ? `Transfer NFT (${this.nft?.name})` : ""}${
                this.type == CustomMessageType.SendRedeemable
                    ? `${getRedeemableIcon(this.redeemable?.redeemableOption?.type)} ${
                          this.redeemable?.redeemableOption?.name
                      }`
                    : ""
            }${this.type == CustomMessageType.SendAsset ? `🧩 ${this.asset.assetOption.name}` : ""}`,
            data: {
                type: this.type,
                amount: this.amount,
                address: this.address,
                currencyId: this.currencyId,
                currencyName: this.currencyName,
                network: this.network,
                transactionId: this.transactionId,
                hash: this.hash,
                nft: this.nft,
                redeemable: this.redeemable,
                asset: this.asset,
            },
            custom: true,
            chatId: chat.id,
            author: this.client,
            to: chat.to,
            state: 0,
        };
        return Message.create(msg);
    }
}
