const darkColors = {
    primary: "#000",
    secondary: "#14D0EA",
    complementary: "#14D0EA",
    labelText: "white",
    tertiary: "#000",
    background: "#000",
    gradientFrom: "#161616",
    gradientTo: "#000",
    grey: "#9091a4",
    white: "white",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "white",
    shadow: "rgba(255,255,255,0.05)",
    secondaryShadow: "rgba(0, 0, 0, 0.1)",
    tertiaryShadow: "rgba(255,255,255,0.08)",
    avatarBase: "#14D0EA",
    incognitoButton: "#363638",
    mainButtonsColor: "white",
};

const settings = {
    fontSizeRatio: {
        light: 1,
        regular: 0.82,
        semiBold: 0.82,
        bold: 0.82,
    },
};

export { darkColors, settings };
