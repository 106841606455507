import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { Animated, KeyboardTypeOptions, StyleSheet, TextInput, TextStyle, View, ViewStyle } from "react-native";

interface Props {
    style?: TextStyle | Array<ViewStyle>;
    onChangeText: (value: any) => void;
    value?: any;
    label?: string;
    multiline?: boolean;
    disable?: boolean;
    editable?: boolean;
    secureTextEntry?: boolean;
    maxLength?: number;
    autoCapitalize?: "none" | "sentences" | "words" | "characters";
}

interface State {
    isFocused: boolean;
}

try {
    require("./input.css");
} catch {}

export default class FloatingTextInput extends Component<Props, State> {
    private _animatedIsFocused: any;
    constructor(props: Props) {
        super(props);

        this.state = {
            isFocused: false,
        };

        this._animatedIsFocused = new Animated.Value(this.props.value === "" ? 0 : 1);
    }

    onChangeText = this.props.onChangeText;

    handleFocus = () => this.setState({ isFocused: true });
    handleBlur = () => this.setState({ isFocused: false });

    componentDidUpdate() {
        Animated.timing(this._animatedIsFocused, {
            toValue: this.state.isFocused || this.props.value !== "" ? 1 : 0,
            duration: 200,
            useNativeDriver: false,
        }).start();
    }

    setType: () => KeyboardTypeOptions = () => "default";

    setAutoCapitalize: () => "none" | "sentences" | "words" | "characters" = () => "none";

    calcFontSizeRatio() {
        let fontSizeRatio = settings.fontSizeRatio?.regular || 1;
        return 16 * fontSizeRatio;
    }

    render() {
        return (
            <View style={[styles.inputContainer, this.props.style]}>
                <Animated.Text
                    style={{
                        color: colors.grey,
                        paddingVertical: this.state.isFocused || this.props.value !== "" ? 8 : 0,
                        paddingHorizontal: 20,
                        position: "absolute",
                        left: 0,
                        top: this._animatedIsFocused.interpolate({
                            inputRange: [0, 1],
                            outputRange: [24, 0],
                        }),
                        fontSize: this._animatedIsFocused.interpolate({
                            inputRange: [0, 1],
                            outputRange: [14, 10],
                        }),
                    }}
                >
                    {this.props.label}
                </Animated.Text>
                <TextInput
                    style={[
                        styles.input,
                        this.props.editable == false && { color: colors.grey },
                        { fontSize: this.calcFontSizeRatio() },
                    ]}
                    onChangeText={this.onChangeText}
                    value={this.props.value}
                    maxLength={this.props.maxLength}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    multiline={this.props.multiline}
                    editable={this.props.editable}
                    selectTextOnFocus={this.props.disable ? false : true}
                    keyboardType={this.setType()}
                    returnKeyType={"done"}
                    secureTextEntry={this.props.secureTextEntry}
                    autoCapitalize={this.props.autoCapitalize || this.setAutoCapitalize()}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    inputContainer: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        minHeight: 65,
        marginBottom: 10,
        flex: 1,
    },
    input: {
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 5,
        fontSize: 16,
        color: colors.text,
        textAlign: "left",
        fontFamily: "NunitoRegular",
        marginTop: 5,
    },
});
