import BoldText from "@base/BoldText";
import ImageBase from "@components/image/ImageBase";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import { BlurView } from "expo-blur";
import React, { Component } from "react";
import { Image, ImageStyle, StyleSheet, View } from "react-native";

interface Props {
    currency: Currency | FiatCurrency;
    styles?: ImageStyle;
    size?: number;
    iconSize?: number;
}

interface State {
    sourceError: boolean;
}

const { t } = i18n;

export default class CurrencyIcon extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sourceError: false,
        };
    }

    render() {
        return (
            <>
                {this.props.currency && (
                    <View
                        style={[
                            {
                                width: this.props.size || 36,
                                height: this.props.size || 36,
                                borderRadius: this.props.size / 2 || 18,
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: "hidden",
                                backgroundColor: getColorOpacity(colors.complementary, 0.4),
                            },
                            this.props.styles,
                        ]}
                    >
                        <View
                            style={{
                                position: "absolute",
                                width: this.props.size || 36,
                                height: this.props.size || 36,
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <BoldText fontSize={this.props.size / 1.5 || 22}>
                                {this.props.currency?.getSymbol()?.substring(0, 1)}
                            </BoldText>
                        </View>
                        <ImageBase
                                style={{
                                    width: this.props.size || 36,
                                    height: this.props.size || 36,
                                }}
                                contentFit="cover"
                                backgroundColor={"transparent"}
                                uri={this.props.currency?.getIcon() || ""}
                            />
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({});
