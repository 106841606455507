import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import { StyleSheet, View } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import Row from "@base/Row";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import GoogleButton from "./components/GoogleButton";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { CustomWelcomeContent } from "@custom/CustomWelcomeContent";
import { ModuleControlService, Services } from "@core/services/ModuleControlService";
import PressableBase from "@base/PressableBase";
import SemiBoldText from "@base/SemiBold";

interface Props {
    navigation: NavigationType;
}

interface State {}

const { t } = i18n;

export default class WelcomeScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressIncognito = this.onPressIncognito.bind(this);
        this.state = {};
    }

    async onPressIncognito() {
        await OAuthService.getInstance().auth({});
        this.props.navigation.navigate(LoginNavigatorScreens.Incognito.routeName);
    }

    render() {
        return (
            <ScreenWrapper>
                <Container style={styles.container}>
                    <CustomWelcomeContent />
                    <View style={styles.buttonsWrapper}>
                        {ModuleControlService.getInstance().isServiceEnabled(Services.oAuthService) ? (
                            <View>
                                <GoogleButton navigation={this.props.navigation} />

                                <PressableBase style={{ paddingTop: 25 }} onPress={this.onPressIncognito}>
                                    <Row style={[styles.button, { backgroundColor: colors.incognitoButton }]}>
                                        <Icon
                                            style={{ paddingLeft: 5 }}
                                            size={20}
                                            color={"white"}
                                            name="incognito"
                                        ></Icon>

                                        <View style={{ flex: 1 }}>
                                            <RegularText fontSize={17} align="center" color={"white"}>
                                                {t("continue")}{" "}
                                                <SemiBoldText fontSize={17} color={"white"}>
                                                    Incognito
                                                </SemiBoldText>
                                            </RegularText>
                                        </View>
                                    </Row>
                                </PressableBase>
                            </View>
                        ) : (
                            <PressableBase onPress={this.onPressIncognito}>
                                <Row
                                    style={[
                                        styles.button,
                                        {
                                            backgroundColor: colors.secondary,
                                            justifyContent: "space-between",
                                            width: 50,
                                            alignItems: "center",
                                        },
                                    ]}
                                >
                                    <View></View>
                                    <RegularText style={{ paddingLeft: 10 }} color={colors.white}>
                                        <BoldText color={colors.white}>{t("continue")}</BoldText>
                                    </RegularText>
                                    <Icon size={18} color={colors.white} name="right"></Icon>
                                </Row>
                            </PressableBase>
                        )}
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    buttonsWrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        minWidth: 300,
        padding: 12,
        alignItems: "center",
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderRadius: settings.mainButtonsRadius || 25,
        overflow: "hidden",
        marginVertical: 5,
    },
});
