import * as React from "react"
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg"

interface Props {
  width: number,
  height: number,
}

function Logo(props: Props) {
  return (
    <Svg
      width={props.width ? props.width : 139}
      height={props.height ? props.height : 28}
      viewBox="0 0 139 28"
      fill="none"
    >
      <Path
        d="M4.58 20.907H.793V0h8.394c2.327 0 4.134.582 5.422 1.745 1.287 1.164 1.93 2.922 1.93 5.274 0 2.054-.668 3.664-2.005 4.827-1.312 1.164-3.094 1.745-5.347 1.745H4.58v7.316zM8.667 3.082H4.58v7.724h4.085c1.337 0 2.414-.321 3.23-.965.818-.644 1.226-1.61 1.226-2.897 0-1.312-.408-2.277-1.225-2.896-.793-.644-1.87-.966-3.231-.966z"
        fill="url(#paint0_linear_47_487)"
      />
      <Path
        d="M17.376 20.907V0h3.714v20.907h-3.714z"
        fill="url(#paint1_linear_47_487)"
      />
      <Path
        d="M27.916 21.279c-2.105 0-3.603-.718-4.494-2.154-.891-1.436-1.337-3.318-1.337-5.645 0-2.352.446-4.246 1.337-5.682.916-1.46 2.414-2.19 4.494-2.19 1.188 0 2.129.21 2.822.63.693.397 1.213.842 1.56 1.338.346.495.557.866.631 1.114h.074V5.645h3.9v15.262h-3.268l-.632-2.488h-.074c-.074.223-.297.57-.668 1.04-.347.445-.88.866-1.597 1.262-.694.372-1.61.558-2.748.558zm1.56-3.194c1.386 0 2.314-.433 2.785-1.3.495-.891.742-1.98.742-3.268 0-1.361-.247-2.463-.742-3.305-.496-.842-1.424-1.262-2.786-1.262-.965 0-1.72.21-2.265.63-.52.422-.891.979-1.114 1.672-.198.668-.297 1.423-.297 2.265 0 .842.099 1.61.297 2.303.223.693.594 1.25 1.114 1.67.545.397 1.3.595 2.265.595z"
        fill="url(#paint2_linear_47_487)"
      />
      <Path
        d="M37.929 20.907V5.72h3.23l.632 3.23h.074c.025-.074.112-.272.26-.594.174-.346.446-.73.817-1.15.397-.422.916-.78 1.56-1.078.669-.322 1.498-.482 2.488-.482 1.362 0 2.414.272 3.157.817a4.549 4.549 0 011.597 2.08c.322.816.52 1.67.594 2.561.075.892.112 1.696.112 2.414v7.39h-3.714V13.48c0-.817-.074-1.56-.223-2.228-.148-.693-.47-1.238-.965-1.634-.47-.42-1.177-.631-2.117-.631-.99 0-1.746.223-2.266.668-.52.446-.879 1.028-1.077 1.745a8.076 8.076 0 00-.297 2.229v7.278H37.93z"
        fill="url(#paint3_linear_47_487)"
      />
      <Path
        d="M60.78 21.279c-1.757 0-3.193-.372-4.308-1.114-1.089-.768-1.906-1.733-2.45-2.897a9.722 9.722 0 01-.818-3.75c-.024-1.536.26-2.897.855-4.086.619-1.213 1.485-2.153 2.6-2.822 1.138-.693 2.475-1.04 4.01-1.04 1.362 0 2.575.297 3.64.892a6.702 6.702 0 012.6 2.525c.643 1.064.977 2.327 1.002 3.787v.966H56.807c0 1.411.346 2.5 1.04 3.268.693.743 1.646 1.114 2.86 1.114.94 0 1.72-.235 2.339-.706.644-.47 1.027-1.089 1.151-1.856h3.677c-.173 1.609-.867 2.97-2.08 4.085-1.213 1.089-2.884 1.634-5.013 1.634zm-3.862-9.247h7.205a7.935 7.935 0 00-.186-.817 3.475 3.475 0 00-.483-1.151c-.222-.396-.57-.73-1.04-1.003-.47-.272-1.077-.408-1.82-.408-.99 0-1.745.21-2.265.63a3.481 3.481 0 00-1.077 1.412c-.198.52-.31.966-.334 1.337z"
        fill="url(#paint4_linear_47_487)"
      />
      <Path
        d="M74.24 20.907c-1.213 0-2.13-.297-2.748-.891-.595-.594-.892-1.56-.892-2.897v-8.8h-2.302V5.644c.718 0 1.275-.075 1.67-.223.397-.149.682-.495.855-1.04.173-.545.26-1.424.26-2.637h3.045v3.9h3.491v2.673h-3.49v9.618h3.564v2.971H74.24z"
        fill="url(#paint5_linear_47_487)"
      />
      <Path
        d="M84.2 21.279c-2.105 0-3.603-.718-4.494-2.154-.891-1.436-1.337-3.318-1.337-5.645 0-2.352.446-4.246 1.337-5.682.916-1.46 2.414-2.19 4.494-2.19 1.188 0 2.129.21 2.822.63.693.397 1.213.842 1.56 1.338.347.495.557.866.631 1.114h.075V5.645h3.899v15.262h-3.268l-.632-2.488h-.074c-.074.223-.297.57-.668 1.04-.347.445-.88.866-1.597 1.262-.693.372-1.61.558-2.748.558zm1.56-3.194c1.386 0 2.314-.433 2.785-1.3.495-.891.742-1.98.742-3.268 0-1.361-.247-2.463-.742-3.305-.496-.842-1.424-1.262-2.786-1.262-.965 0-1.72.21-2.265.63-.52.422-.891.979-1.114 1.672-.198.668-.297 1.423-.297 2.265 0 .842.099 1.61.297 2.303.223.693.594 1.25 1.114 1.67.545.397 1.3.595 2.265.595z"
        fill="url(#paint6_linear_47_487)"
      />
      <Path
        d="M94.399 20.907V5.645h3.528l.26 4.233c.148-.644.433-1.287.854-1.931.445-.668 1.052-1.213 1.82-1.634.792-.446 1.807-.668 3.045-.668h.557v4.233h-.557c-2.129 0-3.615.347-4.457 1.04-.841.668-1.262 1.807-1.262 3.416v6.573h-3.788z"
        fill="url(#paint7_linear_47_487)"
      />
      <Path
        d="M103.735 28v-2.934h2.339c.372 0 .694-.037.966-.111.272-.074.545-.272.817-.594.272-.297.582-.793.928-1.486l1.003-2.228-5.942-15.002h3.9l3.899 11.103 4.197-11.103h3.899l-7.502 17.602c-.594 1.386-1.139 2.413-1.634 3.082-.495.693-1.052 1.139-1.671 1.337-.594.223-1.362.334-2.303.334h-2.896z"
        fill="url(#paint8_linear_47_487)"
      />
      <Path
        d="M118.302 20.907l7.427-11.029L118.896 0h4.494l4.828 7.613L133.083 0h4.419l-6.722 9.878 7.428 11.03h-4.42l-5.57-8.653-5.534 8.652h-4.382z"
        fill="url(#paint9_linear_47_487)"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint6_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint7_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint8_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
        <LinearGradient
          id="paint9_linear_47_487"
          x1={-1.17622}
          y1={-5.96372e-7}
          x2={38.1485}
          y2={82.244}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#69AFF3" />
          <Stop offset={0.260417} stopColor="#979F57" />
          <Stop offset={0.505208} stopColor="#EDA15B" />
          <Stop offset={0.734375} stopColor="#E85396" />
          <Stop offset={1} stopColor="#1E729F" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

export default Logo
