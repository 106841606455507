import CircleButton from "@base/CircleButton";
import { importer } from "@custom/helpers/importer";
import { showScanner } from "@store/actions/global";
import store from "@store/index";
import React, { Component } from "react";
import { View } from "react-native";

interface Props {
    icon: string;
    iconSize?: number;
    navigation: any;
}

export class DefaultFooterFavButton extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
    }
    onPress() {
        store.dispatch(showScanner());
    }
    render() {
        return (
            <View style={{ paddingHorizontal: 10 }}>
                <CircleButton icon={this.props.icon} onPress={this.onPress}></CircleButton>
            </View>
        );
    }
}

const FooterFavButton = importer(DefaultFooterFavButton, "FooterFavButton");

export { FooterFavButton };
