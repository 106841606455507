import { ResizeMode, Video } from "expo-av";
import React, { Component } from "react";
import { ActivityIndicator, Dimensions, Image, ImageStyle, Platform, View, ViewStyle } from "react-native";
import { SkypeIndicator } from "react-native-indicators";

interface Props {
    uri: string;
    style?: ViewStyle | Array<ViewStyle>;
    resizeMode?: ResizeMode;
    posterUri?: string;
    posterStyle?: ImageStyle;
    useNativeControls?: boolean;
    autoSize?: boolean;
    maxAutoSizeHight?: number;
}

interface State {
    height: number;
    loading: boolean;
}

export class VideoBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setVideoSize = this.setVideoSize.bind(this);
        this.state = {
            height: 320,
            loading: false,
        };
    }

    async componentDidMount() {
        if (this.props.autoSize) {
            this.setState({ loading: true });
            Image.getSize(this.props.posterUri || "", (width, height) => this.setImage(width, height));
        }
    }

    setImage = (width, height) => {
        const screenWidth = Dimensions.get("window").width < 600 ? Dimensions.get("window").width : 600;
        this.setState({ height: height / (width / screenWidth) });
    };

    setVideoSize = (data) => {
        if (data?.naturalSize && data.naturalSize?.width && data.naturalSize?.height && this.props.autoSize) {
            this.setState({
                height: data.naturalSize?.height / (data.naturalSize?.width / (Dimensions.get("window").width - 40)),
            });
        }
        this.setState({ loading: false });
    };

    render() {
        return (
            <View style={{ flex: 1 }}>
                <Video
                    style={[
                        this.props.style,
                        this.props.autoSize && Platform.OS !== "web" && { height: this.state.height },
                    ]}
                    videoStyle={Platform.OS == "web" && { position: "relative" }}
                    source={{
                        uri: this.props.uri,
                    }}
                    posterSource={{ uri: this.props.posterUri }}
                    usePoster={true}
                    posterStyle={[
                        this.props.posterStyle,
                        this.props.autoSize && { height: this.state.height } && this.state.loading && { opacity: 0.6 },
                    ]}
                    resizeMode={this.props.resizeMode || ResizeMode.CONTAIN}
                    useNativeControls={this.props.useNativeControls}
                    shouldPlay={true}
                    isLooping={true}
                    isMuted={true}
                    onReadyForDisplay={(data) => this.setVideoSize(data)}
                    onError={() => {
                        this.setState({ loading: false });
                    }}
                    onLoad={(data) => this.setVideoSize(data)}
                />
                {this.props.autoSize && this.state.loading && (
                    <View
                        style={{
                            position: "absolute",
                            width: "100%",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            top: this.state.height / 2 - 30,
                        }}
                    >
                        {Platform.OS == "web" ? (
                            <ActivityIndicator size={60} color="white" />
                        ) : (
                            <SkypeIndicator size={80} animationDuration={2000} color="white" />
                        )}
                    </View>
                )}
            </View>
        );
    }
}

export default VideoBase;
