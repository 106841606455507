import { StackNavigationOptions } from "@react-navigation/stack";
import { Platform } from "react-native";

const NavigatorOptions: StackNavigationOptions = {
  headerShown: false,
  gestureEnabled: false,
  detachPreviousScreen: Platform.OS !== 'ios',
  cardOverlayEnabled: Platform.OS !== 'ios'
}

export default NavigatorOptions;