import RegularText from "@base/RegularText";
import React, { Component } from "react";
import { View, StyleSheet, Platform } from "react-native";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import Currency from "@core/currencies/Currency";

import i18n from "@i18n/i18n";
import NFT from "@custom-types/NFTModel";
import ImageBase from "@components/image/ImageBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import BoldText from "@base/BoldText";
import Row from "@base/Row";
import { getColorOpacity } from "@utils/helpers/global/global";

import { getRedeemableIcon } from "@screens/experiences/components/helpers/redeemable.helper";
import AvatarBase from "@components/avatar/AvatarBase";
import { ASSET } from "@custom-types/ExpercienceModel";

interface Props {
    asset: Partial<ASSET>;
    owner: boolean;
}

interface State {}

const { t } = i18n;

export default class AssetMessage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={{ overflow: "hidden", padding: 5, marginTop: -5, maxWidth: 250 }}>
                <ImageBase
                    style={{
                    
                        height: 200,
                        width: 225,
                        resizeMode: "cover",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                    }}
                  
                    uri={this.props.asset.assetOption.image?.thumbnail || ""}
                ></ImageBase>

                <Row style={{ alignItems: "center", paddingTop: 5,  }}>
                    <View style={styles.circle}>
                        <RegularText fontSize={10}>
                           🧩
                        </RegularText>
                    </View>

                    <RegularText style={{ paddingLeft: 3 }} fontSize={12}>
                        {trimHelper(this.props.asset?.assetOption.name, 25)}
                    </RegularText>
                </Row>

                <Row style={{ alignItems: "center", paddingTop: 5 }}>
                    <AvatarBase
                        size={20}
                        uri={this.props.asset?.merchant?.image?.thumbnail}
                        alias={this.props.asset?.merchant?.name}
                    ></AvatarBase>
                    <RegularText style={{ paddingLeft: 3 }} fontSize={12}>
                        By{" "}
                        <BoldText fontSize={12}>
                            {trimHelper(this.props.asset?.merchant?.name, 20)}
                        </BoldText>
                    </RegularText>
                </Row>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        minWidth: 230,
        paddingHorizontal: 0,
        paddingTop: 10,
        paddingBottom: 5,
    },
    circleWrapper: {
        position: "absolute",
        top: 15,
        left: 15,
    },
    circle: {
        height: 20,
        width: 20,
        borderRadius: 10,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
});
